var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer",
    class: {
      'footer--languages': _vm.$auth.loggedIn !== undefined && !_vm.$auth.loggedIn
    }
  }, [_c('section', {
    staticClass: "section section-end"
  }, [_c('nuxt-link', {
    attrs: {
      "to": _vm.localePath('index')
    }
  }, [_c('img', {
    attrs: {
      "loading": "lazy",
      "src": require("assets/images/blue_logo.svg"),
      "width": "70",
      "height": "24",
      "title": "CVing Logo",
      "alt": "CVing Logo"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "footer-text-links"
  }, _vm._l(_vm.textLinks, function (link, index) {
    return _c('div', {
      key: 'link_' + link.name + '_' + index,
      staticClass: "link"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.handleLinkClick(link);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(link.name))])])]);
  }), 0)], 1), _vm._v(" "), _c('section', {
    staticClass: "section section-copyright"
  }, [_vm._v("\n    Copyright " + _vm._s(_vm.$dayjs().year()) + " © CVing S.r.l. | P. IVA 04681350270 |\n    REA MI - 2515112 | Aut. Def. ANPAL n. 0000142 del 28/11/2023\n  ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }