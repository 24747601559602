
export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuOtherItems: [
        {
          to: 'https://business.cving.com/per-le-aziende',
          icon: 'pi pi-building',
          text: this.$t('menu.forCompanies')
        },
        {
          to: 'https://media.cving.com/',
          icon: 'pi pi-comments',
          text: this.$t('menu.blog')
        },
        {
          to: '',
          icon: 'pi pi-map-marker',
          text: this.$t('menu.events'),
          condition: this.$config.p2p
        },
        {
          to: 'https://support.cving.com/hc/it',
          icon: 'pi pi-info-circle',
          text: this.$t('menu.assistence'),
          condition: this.$config.p2p
        }
      ],
      menuItems: [
        {
          to: this.localePath('/'),
          routeName: 'index',
          icon: 'pi pi-home',
          text: this.$t('menu.home')
        },
        {
          to: this.localePath({
            name: 'radar',
            query: {
              p: '1',
              ea: '0',
              verified: 'true',
              suggested: false,
              order: '0'
            }
          }),
          routeName: 'radar',
          icon: 'pi pi-search',
          text: this.$t('menu.radar')
        },
        {
          to: this.localePath('curriculum'),
          routeName: 'curriculum',
          icon: 'pi pi-upload',
          text: 'Carica il Cv',
          condition: this.$config.p2p
        },
        {
          to: this.localePath('user-dashboard-career-home'),
          routeName: 'user-dashboard',
          icon: 'pi pi-money-bill',
          text: 'Suggerisci un lavoro',
          condition: this.$config.p2p
        },
        {
          to: '',
          routeName: 'new-positions',
          icon: 'pi pi-star',
          text: 'Nuove posizioni',
          condition: this.$config.p2p
        }
      ],
      textLinks: [{
        name: 'Privacy',
        link: 'https://media.cving.com/privacy/'
      },
      {
        name: this.$t('footer.termsAndConditiones').toString(),
        link: 'https://media.cving.com/terms-conditions/'
      },
      {
        name: this.$t('footer.transparency').toString(),
        link: 'https://media.cving.com/trasparenza/'
      }
      ]
    }
  },
  methods: {
    openLink(link) {
      this.$emit('closeMenu')
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    isActive(routeName) {
      console.log(this.$route.name)
      console.log(routeName)
      return this.$route.name ? this.$route.name.startsWith(routeName) : false
    },
    trackMenu(menu) {
      this.$emit('closeMenu')
      console.log('track', menu)
      this.$gtm.push({
        event: 'click_menu',
        event_data: {
          menu_location: 'header',
          menu_type: 'button',
          menu_text: menu,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document.title : 'CVing'
        }
      })
      if (menu === this.$t('menu.suggest')) {
        const sectionFormatted = this.$t('dashboard.career') + '😎'
        this.$gtm.push({
          event: 'orp_dashboard',
          event_data: {
            section: sectionFormatted.replace(/\s/g, ''),
            sub_section: 'home',
            page_url: this.$router.currentRoute.fullPath,
            page_title: document ? document.title : 'CVing'
          }
        })
      }
    }
  }
}
